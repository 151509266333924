<template>
  <div id="app" @mousemove="mouseMove" @keydown="mouseMove" @keyup="mouseMove">
    <router-view />
  </div>
</template>

<script >
export default {
  methods: {
    mouseMove() {
      sessionStorage.setItem("lastTime", new Date().getTime())
    },
  }
}

// var body = document.getElementsByName("body");
// var div = document.getElementById('app');
// console.log(213123123123123, div)
// div.onmouseover = function (e) {   // 注册移过事件处理函数
//   // this.style.border = "solid blue";
//   console.log(213123123123123, div)
// }
// div.onmouseout = function () {   // 注册移出事件处理函数
//   // this.style.border = "solid red";
//   console.log(111111111111111, e)
// }
// var tip;
// var imouse = 0;
// var timer;

// function startup() {
//   tip = document.getElementById("tip");
// }
// function mouseIn() {
//   timer = window.setInterval(foo, 1000);
// }
// function mouseOut() {
//   window.clearInterval(timer);
// }

// var foo = function () {
//   if (imouse == 0) {
//     tip.innerHTML = "still";
//   }
//   imouse = 0;
// }
</script>
<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: auto;
    background: linear-gradient(
      180deg,
      #00a0b2 0%,
      #00adb6 4%,
      #0098ae 20%,
      #0078a6 57%,
      #164e88 100%
    );
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  template {
  }
</style>
