<template>
  <!-- <div class="sliderbar_box"> -->
  <div :class="['sliderbar_box', isCollapse ? 'submenu_active_box' : 'sliderbar_box']">
    <el-menu ref="menu" :default-active="activeIndex" :collapse-transition="false" show-timeout="0" hide-timeout="0"
      :unique-opened="true" class="el-menu-vertical-demo" :collapse="isCollapse" :router="true" @open="handleOpen"
      @close="handleClose" @select="handleSelect" background-color="#545c64" text-color="#fff">
      <!-- mode="horizontal" menu-trigger="click" -->
      <div v-if="isCollapse" class="sliderbar_box_title_stow">
        <div class="sliderbar_box_title_icon_stow">
          <img class="sliderbar_box_title_icon_stow_icon" src="@/assets/img/company_logo1.png" alt="">
        </div>
        <div class="sliderbar_box_title_more" @click="toStow()">
          <span class="iconfont icon-zhankai"></span>
        </div>
      </div>
      <div v-if="!isCollapse" class="sliderbar_box_title">
        <div class="sliderbar_box_title_icon">
          <img src="@/assets/img/logo_and_title1.png" alt="">
        </div>
        <div class="sliderbar_box_title_more" @click="toStow()">
          <span class="iconfont icon-shouqi"></span>
        </div>
      </div>
      <!-- 正式 开始 -->
      <div class="menu_list">
        <div v-for="item in leftMenu" :key="item.menuId">
          <el-submenu v-if="item.childMenu && item.childMenu.length > 0 && item.isCheck == 1" :index="item.menuId">
            <div slot="title">
              <i :class="['iconfont', 'iconfont_right', item.icon]" v-if="!isCollapse"></i>
              <el-tooltip :content="item.menuName" placement="top-end" :visible-arrow="false" popper-class="tooltip1"
                v-if="isCollapse">
                <i :class="['iconfont', 'iconfont_right', item.icon]"></i>
              </el-tooltip>
              <span class="iconfont_title submenu_more" :style="{ 'display': isCollapse ? 'none' : '' }" slot="title">{{
    item.menuName
  }}</span>
              <i v-if="!isCollapse" class="iconfont icon-xiajiantou iconfont_more"></i>
            </div>
            <el-menu-item-group>
              <el-menu-item v-for="item in item.childMenu.filter(item => item.isCheck == 1)" :key="item.menuId"
                :index="item.path" @click="toWorkBench">{{ item.menuName }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <div :class="[isCollapse ? 'submenu_active_box' : 'submenu_active_box_bottom']"
            v-if="!item.childMenu && item.isCheck == 1">
            <el-menu-item :index="item.path">
              <i :class="['iconfont', 'iconfont_right', item.icon]" v-if="!isCollapse"></i>
              <el-tooltip :content="item.menuName" placement="top-end" :visible-arrow="false" popper-class="tooltip1"
                v-if="isCollapse">
                <i :class="['iconfont', 'iconfont_right', item.icon]"></i>
              </el-tooltip>
              <span class="iconfont_title" slot="title" :style="{ 'display': isCollapse ? 'none' : '' }">{{
    item.menuName
                }}</span>
            </el-menu-item>
          </div>
        </div>
      </div>

    </el-menu>
  </div>
</template>

<script>
import { getLeftMenu, haveNews, editBright } from '@/api/api'
// 在 vue组件中引入
import { warningClose } from '@/utils/message'
import bus from '../../../utils/bus'

export default {
  name: 'sliderBar',
  mounted() {
    // api.getList().then(res => {
    // })
    // this.drawLine();
    // 使用init初始化
    // this.WorkBenchUrl = './workbench'
  },
  data() {
    return {
      powerList: JSON.parse(localStorage.getItem('powerList')),
      Menu: '',
      isCollapse: false,
      stowSrc: '',
      sliderUrl: '',
      activeIndex: '',
      leftMenu: [], // 左侧菜单
      isRedPoint: '' // 1 有红点
    }
  },
  created() {
    this.getMenu()
    // 生命周期获取index并赋值
    this.$emit('change', localStorage.getItem('index'))
    const index = localStorage.getItem('index')
    if (index) {
      this.activeIndex = index
    }
  },
  watch: {
    async $route(n, o) {
      warningClose()
      this.activeIndex = '.' + n.path
      localStorage.setItem('index', this.activeIndex)
      let data = []
      this.leftMenu.map(item => {
        if (item.childMenu != null) {
          item.childMenu.map(itemC => {
            data.push(itemC)
          })
        } else {
          data.push(item)
        }
      })
      let menuId = data.filter(item => item.path == this.activeIndex)[0].menuId
      localStorage.setItem('menuId', menuId)
      await this.editBright(this.activeIndex).then(() => {
        this.busFun(this.isRedPoint)
        setTimeout(() => {

        }, 1000)
      })
    }
  },
  methods: {
    busFun(data) {
      // 通过 $emit 来触发方法，参数1 是定义方法名，参数2 是你要发送的数据
      bus.$emit('name', data)
    },
    async haveNews(val1) {
      await haveNews().then(res => {
        if (res.code == 200 && res.data != null) {
          this.isRedPoint = res.data.isBright
          // if (val1 == './noticeDataManage') {
          //   this.isRedPoint = res.data.isBright
          //   // localStorage.setItem('isRedPoint', this.isRedPoint)
          // } else {
          //   this.isRedPoint = res.data.isBright
          //   // localStorage.setItem('isRedPoint', this.isRedPoint)
          // }
        }
      })
    },
    async editBright(val1) {
      if (val1 != './noticeDataManage') {
        this.haveNews(val1)
      } else {
        await editBright().then(res => {
          if (res.code == 200) {
            this.haveNews(val1)
          }
        })
      }
    },
    getMenu() {
      getLeftMenu('authId=' + localStorage.getItem('roleId')).then(res => {
        this.leftMenu = res.data
      })
    },
    toStow(e) {
      this.isCollapse = !this.isCollapse
      this.$emit('change', this.isCollapse)
      this.$store.state.isCollapse = this.isCollapse
    },
    toWorkBench(e) {
      this.$emit('change', e.index, this.isCollapse)
    },
    selectMenu(index, path) {
      // 存储index的值
      localStorage.setItem('index', index)
    },
    handleSelect(index) {
      this.$refs.menu.close(this.Menu)
    },
    handleOpen(key, keyPath) {
      this.Menu = key
    },
    handleClose(key, keyPath) {

    }
  }
}

</script>
<style>
/* 取消过渡效果 */
.el-menu-item {
  transition-duration: 0s !important;
}
</style>
<style>
.menu_list .el-submenu__icon-arrow {
  display: none;
}

.aside_box ::-webkit-scrollbar {
  width: 0px;
}

.aside_box_stow ::-webkit-scrollbar {
  width: 0px;
}

.menu_list {
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.el-tooltip__popper {
  display: none;
}

.el-tooltip__popper.tooltip1 {
  display: block;
  width: 130px !important;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: center;
  color: #009ea7 !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.5) !important;
  border-radius: 8px;
  margin-bottom: 10px !important;
  margin-left: 20px !important;
}

.el-tooltip__popper .iconfont_title {
  /* display: none; */
  width: 125px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  opacity: 1;
  color: #009ea7 !important;
}

.el-tooltip__popper .popper__arrow {
  display: none !important;
}

.el-menu-item {
  line-height: 20px;
  font-size: 14px !important;
}

.submenu_active_box_bottom {
  width: 190px;
  margin-bottom: 15px;
  /* opacity: 0.59; */
}

.submenu_active_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.el-menu-item-group__title {
  padding: 0 !important;
}

.submenu_more {
  margin-right: 20px;
}

.el-menu-vertical-demo {
  display: flex;
  flex-direction: column;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 270px;
  min-width: 100px;
}

.sliderbar_box .el-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
  background-color: rgb(0 0 0 / 0%) !important;
}

.sliderbar_box .el-submenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;
}

.sliderbar_box .el-submenu .el-menu-item {
  min-width: 124px !important;
  left: 10px !important;
}

.sliderbar_box .el-submenu .el-menu-item.is-active {
  width: 124px !important;
  height: 40px !important;
  color: #009ea7 !important;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: normal;
  line-height: 40px;
  /* background: #ffffff !important; */
  box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.37) !important;
  border-radius: 14px !important;
  opacity: 1;
}

.sliderbar_box .el-submenu .el-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px !important;
  height: 40px !important;
  padding: 0 !important;
  min-width: 124px !important;
  left: 10px !important;
}

.sliderbar_box .el-menu-item {
  width: auto !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  background-color: rgb(0 0 0 / 0%) !important;
  opacity: 0.59;
}

.iconfont_right {
  margin-right: 10px;
  background: linear-gradient(180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.7) 100%) !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
}

.iconfont_more {
  color: #ffffff !important;
  opacity: 0.59;
  font-size: 12px !important;
}

.el-icon-arrow-down:before {
  content: none !important;
}

.iconfont_title_no {
  height: 22px;
  color: #009ea7 !important;
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bolder;
  line-height: 21px;
}

.sliderbar_box .el-submenu__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  height: 40px;
  background-color: rgb(0 0 0 / 0%) !important;
  font-size: 14px;
  padding: 20px !important;
  opacity: 0.59;
  margin-bottom: 10px;
}

.sliderbar_box .el-menu--collapse {
  width: 100px;
}

.sliderbar_box .el-menu-item.is-active {
  /* width: 190px !important; */
  height: 40px !important;
  line-height: 40px !important;
  color: #009ea7 !important;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  background: #ffffff !important;
  box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.37) !important;
  border-radius: 14px !important;
  opacity: 1;
}

/* 文字 */
.el-submenu.is-active>.el-submenu__title {
  /* color: #009ea7 !important; */
  /* background: #ffffff !important; */
  /* border-radius: 14px; */
  margin-bottom: 10px;
  opacity: 1;
  font-weight: bold;
}

.el-submenu.is-active>.el-menu-item-groupc {
  /* opacity: 1; */
}

/* icon图标也跟着变 */
/* .el-submenu.is-active>.el-submenu__title i {
                                                                                                                                                                      background: linear-gradient(180deg, #009EA7 0%, rgba(0, 158, 167, 0.7) 100%) !important;
                                                                                                                                                                      -webkit-background-clip: text !important;
                                                                                                                                                                      color: transparent !important;
                                                                                                                                                                    } */

.submenu_active_box .el-submenu__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  /* left: 10px; */
  left: 0;
}

.submenu_active_box .el-menu-item {
  /* width: 40px !important; */
  height: 40px !important;
}

.submenu_active_box .el-menu-item.is-active {
  width: 40px !important;
  height: 40px !important;
  line-height: 20px;
  color: #009ea7 !important;
  font-size: 16px;
  font-weight: normal;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  background: #ffffff !important;
  box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.37) !important;
  border-radius: 14px !important;
}

.submenu_active_box .el-dropdown-menu__item,
.el-menu-item {
  padding: 0 !important;
}

.submenu_active_box .iconfont_right {
  margin-right: 0 !important;
}

.sliderbar_box .el-dropdown-menu__item,
.el-menu-item {
  padding: 20px !important;
}

/* .sliderbar_box .el-tooltip */
.el-menu-item.is-active>div,
.el-menu-item>div {
  height: 40px !important;
  line-height: 40px;
  padding: 0 !important;
  /* width: 40px !important; */
  /* width: 0 !important; */
}

.sliderbar_box .el-menu-item.is-active .iconfont_title,
.sliderbar_box .el-menu-item.is-active .iconfont_right {
  /* opacity: 1 !important; */
  background: linear-gradient(180deg,
      #009ea7 0%,
      rgba(0, 158, 167, 0.7) 100%) !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
}

.sliderbar_box_title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 120px;
}

.sliderbar_box_title_stow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 120px;
}

.sliderbar_box_title_icon {
  width: 162px;
  height: 46px;
}

.sliderbar_box_title_icon_stow {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}

.sliderbar_box_title_icon_stow_icon {
  display: flex;
  cursor: pointer;
}

.sliderbar_box_title_more {
  width: 16px;
  height: 16px;
  margin-top: 7px;
  color: #ffffff;
  opacity: 0.59;
}

.el-submenu__title .iconfont_right,
.el-menu-item .iconfont_right {
  width: 20px;
  text-align: left;
}

.sliderbar_box_title_more .icon-shouqi {
  font-size: 20px;
}

.sliderbar_box_title_more .icon-zhankai {
  font-size: 20px;
}

.icon-gongzuotai-06 {
  font-size: 20px !important;
}

.icon-yiyuan {
  font-size: 20px !important;
}

.icon-huanzheguanli {
  font-size: 20px !important;
}

.icon-xiangmu {
  font-size: 20px !important;
}

.icon-tianchongxing- {
  font-size: 20px !important;
}

.icon-zhishikuguanli- {
  font-size: 20px !important;
}

.icon-dingdan {
  font-size: 20px !important;
}

.icon-dakaixinxi {
  font-size: 20px !important;
}

.icon-mianxingyishengtubiao3 {
  font-size: 20px !important;
}

.icon-permissions-user {
  font-size: 20px !important;
}

.icon-zhanghuguanli {
  font-size: 20px !important;
}

.el-menu-item-group>ul {
  width: 135px;
}

/* 悬浮 */

.el-menu--horizontal {
  position: absolute;
  left: 0;
  z-index: 2024;
}

.el-menu--popup {
  z-index: 100;
  min-width: 125px !important;
  border: none;
  padding: 10px 0 !important;
  border-radius: 14px !important;
  box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.4) !important;
  background: #ffffff !important;
}

.el-menu--vertical .el-menu-item-group {
  margin-left: 0;
}

.el-menu--vertical .el-menu .el-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
  color: #009ea7 !important;
  background-color: #ffffff !important;
}

.el-menu--vertical .el-menu-item:hover {
  background-color: rgba(0, 158, 167, 0.2) !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0;
}

.el-menu--vertical .el-menu-item-group .el-menu-item.is-active {
  background-color: rgba(0, 158, 167, 0.2) !important;
}

.el-submenu.is-active>.el-menu--inline {
  height: auto !important;
}

/* 媒体查询 自适应 */
@media screen and (max-width: 1680px) {
  .menu_list {
    height: calc(100vh - 80px);
  }

  .el-tooltip__popper.tooltip1 {
    width: 100px !important;
    height: 26px;
    line-height: 26px;
    padding: 0 5px;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 7px !important;
    margin-left: 10px !important;
  }

  .el-menu-item-group ul {
    width: 92px;
  }

  .el-menu-item {
    line-height: 13px;
    font-size: 12px !important;
  }

  .submenu_active_box_bottom {
    width: 133px;
    margin-bottom: 10px;
  }

  .submenu_active_box {
    margin-bottom: 10px;
  }

  .submenu_more {
    margin-right: 10px;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 180px;
    min-width: 66px;
  }

  .el-submenu .el-menu {
    margin-top: 10px;
  }

  .sliderbar_box .el-submenu .el-menu-item.is-active {
    width: 90px !important;
    height: 26px !important;
    font-size: 12px;
    line-height: 13px;
    border-radius: 9px !important;
  }

  .sliderbar_box .el-submenu .el-menu-item {
    margin-bottom: 10px;
    width: 90px !important;
    height: 26px !important;
    min-width: 90px !important;
    left: 3px !important;
  }

  .iconfont_right {
    margin-right: 7px;
  }

  .iconfont_title {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
  }

  .iconfont_title_no {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
  }

  .sliderbar_box .el-submenu__title {
    width: 133px;
    height: 26px;
    font-size: 12px;
    padding: 10px !important;
    margin-bottom: 5px;
  }

  .sliderbar_box .el-menu--collapse {
    width: 66px;
  }

  .sliderbar_box .el-menu-item {
    height: 26px !important;
  }

  .sliderbar_box .el-menu-item.is-active {
    height: 26px !important;
    line-height: 13px;
    font-size: 12px;
    border-radius: 9px !important;
  }

  /* 文字 */
  .el-submenu.is-active>.el-submenu__title {
    border-radius: 9px;
    margin-bottom: 5px;
  }

  .submenu_active_box .el-submenu__title {
    width: 26px !important;
    height: 26px !important;
    left: 0;
  }

  .submenu_active_box .el-menu-item {
    /* width: 26px !important; */
    height: 26px !important;
  }

  .submenu_active_box .el-menu-item.is-active {
    width: 26px !important;
    height: 26px !important;
    line-height: 13px;
    font-size: 12px;
    border-radius: 9px !important;
  }

  .sliderbar_box .el-dropdown-menu__item,
  .el-menu-item {
    padding: 10px !important;
  }

  /* .sliderbar_box .el-tooltip  */
  .el-menu-item.is-active>div,
  .el-menu-item>div {
    /* width: 26px !important; */
    height: 26px !important;
    line-height: 26px;
  }

  .sliderbar_box_title {
    height: 80px;
  }

  .sliderbar_box_title_stow {
    height: 80px;
  }

  .sliderbar_box_title_icon {
    width: 97px;
    height: 30px;
  }

  .sliderbar_box_title_icon_stow {
    width: 30px;
    height: 30px;
    margin-right: 7px;
  }

  .sliderbar_box_title_more {
    width: 14px;
    height: 14px;
    margin-top: 0;
  }

  .el-submenu__title .iconfont_right,
  .el-menu-item .iconfont_right {
    width: 14px;
    text-align: left;
  }

  .sliderbar_box_title_more .icon-shouqi {
    font-size: 14px;
  }

  .sliderbar_box_title_more .icon-zhankai {
    font-size: 14px;
  }

  .icon-gongzuotai-06 {
    font-size: 14px !important;
  }

  .icon-yiyuan {
    font-size: 14px !important;
  }

  .icon-huanzheguanli {
    font-size: 14px !important;
  }

  .icon-xiangmu {
    font-size: 14px !important;
  }

  .icon-tianchongxing- {
    font-size: 14px !important;
  }

  .icon-zhishikuguanli- {
    font-size: 14px !important;
  }

  .icon-dingdan {
    font-size: 14px !important;
  }

  .icon-dakaixinxi {
    font-size: 14px !important;
  }

  .icon-mianxingyishengtubiao3 {
    font-size: 14px !important;
  }

  .icon-permissions-user {
    font-size: 14px !important;
  }

  .icon-zhanghuguanli {
    font-size: 14px !important;
  }

  /* 悬浮 */
  .el-menu--popup {
    min-width: 95px !important;
    padding: 5px 0 !important;
    border-radius: 9px !important;
  }

  .el-menu--vertical .el-menu .el-menu-item {
    width: 95px;
    height: 26px !important;
    line-height: 26px !important;
    font-size: 12px !important;
  }
}
</style>
