import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Plugin from 'v-fit-columns';
import 'element-ui/lib/theme-chalk/index.css'
import './perssion'
//main.js
import axios from 'axios'
import * as echarts from 'echarts'

import '@/assets/iconfont/iconfont.css'
import '@/assets/css/styles.css'
import tinymce from "tinymce";
import Astrict from '@/utils/astrict'
import OverFlowTip from "@/components/overFlowTip.vue"
Vue.use(Astrict)
Vue.prototype.$tinymce = tinymce
// import Editor from "@tinymce/tinymce-vue";
// import 'tinymce/themes/silver/theme'
//如果报错找不到  import 'tinymce/themes/modern/theme',可以替换成 import 'tinymce/themes/silver/theme'
//不过一般都会报错,说找不到的....
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.use(Plugin);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.component("OverFlowTip",OverFlowTip)
// Vue.use(tinymce)
// Vue.use(Editor);
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/devapi'        //关键代码
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
