// router.js
import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout/layout'
// import login from '../views/login/index'
Vue.use(Router)
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// const dashboard = resolve => require(['../views/login/index'], resolve);

// const routeList = {
//   path: '/AboutView',
//   component: () => import('@/views/AboutView'),
//   name: '首页123123',
// }
const createRouter = () => new Router({
  // mode: 'history',
  // base: "/doctor-patient-platform-management/",
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: '/login',
      children: [
        {
          path: '/login',
          // redirect: '/workbench/index',
          component: () => import('@/views/login'),
          name: '登录',
          meta: {
            keepAlive: false,
            title: '登录'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/workbench',
      children: [
        {
          path: '/workbench',
          // redirect: '/workbench/index',
          component: () => import('@/views/workbench'),
          name: '工作台',
          meta: {
            keepAlive: true,
            title: '工作台'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/HsptInfoManage',
      meta: {
        title: '医院信息管理'
      },
      children: [
        {
          path: '/hsptDataManage',
          component: () => import('@/views/HsptInfoManage/hsptDataManage'),
          name: '医院数据管理',
          meta: {
            keepAlive: true,
            title: '医院数据管理'
          }
        },
        {
          path: '/departManage',
          component: () => import('@/views/HsptInfoManage/departManage'),
          name: '科室数据管理',
          meta: {
            keepAlive: true,
            title: '科室数据管理'
          }
        },
        {
          path: '/pathologicalManage',
          component: () => import('@/views/HsptInfoManage/PathologicalManage'),
          name: '病理数据管理',
          meta: {
            keepAlive: true,
            title: '病理数据管理'
          }
        },
        {
          path: '/doctorManage',
          component: () => import('@/views/HsptInfoManage/doctorManage'),
          name: '医院人员管理',
          meta: {
            keepAlive: true,
            title: '医院人员管理'
          }
        },
        {
          path: '/commonTestManage',
          component: () => import('@/views/HsptInfoManage/commonTestManage'),
          name: '常用检测管理',
          meta: {
            keepAlive: true,
            title: '常用检测管理'
          }
        },
        {
          path: '/followCalendarManage',
          component: () => import('@/views/HsptInfoManage/followCalendarManage'),
          name: '随访日历管理',
          meta: {
            keepAlive: true,
            title: '随访日历管理'
          }
        },
        {
          path: '/researchGroupManage',
          component: () => import('@/views/HsptInfoManage/researchGroupManage'),
          name: '研究群组管理',
          meta: {
            keepAlive: true,
            title: '研究群组管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/PatientInfoManage',
      meta: {
        title: '患者信息管理'
      },
      children: [
        {
          path: '/patientManage',
          component: () => import('@/views/PatientInfoManage/patientManage'),
          name: '患者数据管理',
          meta: {
            keepAlive: true,
            title: '患者数据管理'
          }
        },
        {
          path: '/patientCalendarManage',
          component: () => import('@/views/PatientInfoManage/patientCalendarManage'),
          name: '患者日历管理',
          meta: {
            keepAlive: true,
            title: '患者日历管理'
          }
        },
        {
          path: '/patientReportManage',
          component: () => import('@/views/PatientInfoManage/patientReportManage'),
          name: '患者报告管理',
          meta: {
            keepAlive: true,
            title: '患者报告管理'
          }
        },
        
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/ProjectActivityManagement',
      meta: {
        title: '项目活动管理'
      },
      children: [
        {
          path: '/projectDataManage',
          component: () => import('@/views/ProjectActivityManagement/projectDataManage'),
          name: '项目数据管理',
          meta: {
            keepAlive: true,
            title: '项目数据管理'
          }
        },
        {
          path: '/projectCalendarManage',
          component: () => import('@/views/ProjectActivityManagement/projectCalendarManage'),
          name: '项目日历管理',
          meta: {
            keepAlive: true,
            title: '项目日历管理'
          }
        },
        {
          path: '/taskDataManage',
          component: () => import('@/views/ProjectActivityManagement/taskDataManage'),
          name: '课题数据管理',
          meta: {
            keepAlive: true,
            title: '课题数据管理'
          }
        },
        {
          path: '/groupDataManage',
          component: () => import('@/views/ProjectActivityManagement/groupDataManage'),
          name: '入组数据管理',
          meta: {
            keepAlive: true,
            title: '入组数据管理'
          }
        },
        {
          path: '/ActiveDataManage',
          component: () => import('@/views/ProjectActivityManagement/activeDataManage'),
          name: '活动数据管理',
          meta: {
            keepAlive: true,
            title: '活动数据管理'
          }
        },
        {
          path: '/detectionDataManage',
          component: () => import('@/views/ProjectActivityManagement/detectionDataManage'),
          name: '检测数据管理',
          meta: {
            keepAlive: true,
            title: '检测数据管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/scienceTextManage',
      children: [
        {
          path: '/scienceTextManage',
          // redirect: '/workbench/index',
          component: () => import('@/views/ScienceTextManage'),
          name: '科普推文管理',
          meta: {
            keepAlive: true,
            title: '科普推文管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/knowledgeManage',
      children: [
        {
          path: '/knowledgeManage',
          // redirect: '/workbench/index',
          component: () => import('@/views/KnowledgeManage'),
          name: '知识库管理',
          meta: {
            keepAlive: true,
            title: '知识库管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/orderDataManage',
      children: [
        {
          path: '/orderDataManage',
          // redirect: '/workbench/index',
          component: () => import('@/views/OrderDataManage'),
          name: '订单数据管理',
          meta: {
            keepAlive: true,
            title: '订单数据管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/noticeDataManage',
      children: [
        {
          path: '/noticeDataManage',
          // redirect: '/workbench/index',
          component: () => import('@/views/NoticeDataManage'),
          name: '通知信息管理',
          meta: {
            keepAlive: true,
            title: '通知信息管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/AssistantDataManage',
      meta: {
        title: '机构信息管理'
      },
      children: [
        {
          path: '/departPersionManage',
          component: () => import('@/views/AssistantDataManage/departPersionManage'),
          name: '机构信息管理',
          meta: {
            keepAlive: true,
            title: '机构信息管理'
          }
        },
        {
          path: '/departDataManage',
          component: () => import('@/views/AssistantDataManage/departDataManage'),
          name: '机构人员管理',
          meta: {
            keepAlive: true,
            title: '机构人员管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/AccountInfoManage',
      meta: {
        title: '账户信息管理'
      },
      children: [
        {
          path: '/sysAccountManage',
          component: () => import('@/views/AccountInfoManage/sysAccountManage'),
          name: '系统账户管理',
          meta: {
            keepAlive: true,
            title: '系统账户管理'
          }
        },
        {
          path: '/doctorAccountManage',
          component: () => import('@/views/AccountInfoManage/doctorAccountManage'),
          name: '医院账户管理',
          meta: {
            keepAlive: true,
            title: '医院账户管理'
          }
        },
        {
          path: '/AssistantManage',
          component: () => import('@/views/AccountInfoManage/AssistantManage'),
          name: '机构账户管理',
          meta: {
            keepAlive: true,
            title: '机构账户管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/rolePermissionsManage',
      children: [
        {
          path: '/rolePermissionsManage',
          // redirect: '/workbench/index',
          component: () => import('@/views/RolePermissionsManage'),
          name: '角色权限管理',
          meta: {
            keepAlive: true,
            title: '角色权限管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/BaseDataManage',
      meta: {
        title: '基础数据管理'
      },
      children: [
        {
          path: '/treatPlanManage',
          component: () => import('@/views/BaseDataManage/treatPlanManage'),
          name: '治疗方案管理',
          meta: {
            keepAlive: true,
            title: '治疗方案管理'
          }
        },
        {
          path: '/testItemManage',
          component: () => import('@/views/BaseDataManage/testItemManage'),
          name: '检测项目管理',
          meta: {
            keepAlive: true,
            title: '检测项目管理'
          }
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      redirect: '/dataResearch',
      children: [
        {
          path: '/dataResearch',
          // redirect: '/workbench/index',
          component: () => import('@/views/DataResearch'),
          name: '数据研究工作站',
          meta: {
            keepAlive: true,
            title: '数据研究工作站'
          }
        }
      ]
    }
  ]
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

// export function addDynamicMenuAndRoutes(menus) {
//   // 添加动态路由
//   // 添加动态路由前，重置默认路由，防止登出后路由不清空
//   resetRouter()
//   const dynamicRoutes = addDynamicRoutes(menus)
//   dynamicRoutes.push({
//     path: '*',
//     redirect: '/404'
//   })
//   // 动态路由添加到第一个路由的子路由中
//   router.options.routes[0].children = dynamicRoutes
//   router.addRoute(routeList)
// }

// /**
// * 添加动态(菜单)路由
// * @param {*} menuList 菜单列表
// * @param {*} routes 递归创建的动态(菜单)路由
// */
// export function addDynamicRoutes(menuList = [], routes = []) {
//   var temp = []
//   for (var i = 0; i < menuList.length; i++) {
//     if (menuList[i].children && menuList[i].children.length >= 1) {
//       temp = temp.concat(menuList[i].children)
//     } else if (menuList[i].resUrl && /\S/.test(menuList[i].resUrl)) {
//       menuList[i].resUrl = menuList[i].resUrl.replace(/^\//, '')
//       // 创建路由配置
//       var route = {
//         path: menuList[i].resUrl,
//         component: null,
//         name: menuList[i].resName,
//         meta: {
//           icon: menuList[i].icon,
//           index: menuList[i].id
//         }
//       }
//       // if (route.path.indexOf('ada') < 0) { // 临时过滤路由
//       //   continue
//       // }
//       try {
//         // 根据菜单URL动态加载vue组件
//         let filePath = menuList[i].resFile
//         if (filePath && /\S/.test(filePath)) { filePath = filePath.replace(/^\//, '') }
//         route['component'] = resolve => require([`@/${filePath}`], resolve)
//       } catch (e) { // console.log(e)
//       }
//       routes.push(route)
//     }
//   }
//   if (temp.length >= 1) {
//     addDynamicRoutes(temp, routes)
//   } else {
//     console.log('动态路由加载...')
//     console.log(routes)
//     console.log('动态路由加载完成.')
//   }
//   return routes
// }

export default router
// import Login from '../views/login/';

// 使用了vue-routerd的[Lazy Loading Routes
// ](https://router.vuejs.org/en/advanced/lazy-loading.html)

// 所有权限通用路由表
// 如首页和登录页和一些不用权限的公用页面
// const constantRouterMap = [
//   {
//     path: '/',
//     component: dashboard,
//     redirect: '/HomeView',
//     children: [
//       {
//         path: '/HomeView',
//         component: () => import('@/views/HomeView'),
//         name: '首页',
//       }
//     ]
//   },
// ]

// //异步挂载的路由
// //动态需要根据权限加载的路由表
// export const asyncRouterMap = [
//   {
//     path: 'AboutView',
//     component: test,
//     name: '权限测试',
//     children: [
//       {
//         path: '/AboutView',
//         component: () => import('@/views/AboutView'),
//         name: '权限测试页',
//       }]
//   },
//   { path: '*', redirect: '/404', hidden: true }
// ];
