<template>
  <router-view :key="key" />
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$router.fullPath
    }
  },
  components: {},
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    toWorkBench() {
      this.$router.push({
        path: './workbench'
      })
    }

  }
}
</script>

<style scoped>
</style>