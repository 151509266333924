import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  let newT = router.options.routes[0];/* 需要在里面动态添加子路由的一个根组,插入的也是这个根组对象 */
  if (newT.children.length == 1) {//条件保护，防止全局路由守卫死循环执行。
    newT.children.unshift({
      path: "/AboutView",
      name: '测试页',
      component: () => import("@/views/AboutView.vue"),
      children: [
        {
          path: '/HomeView',
          component: () => import('@/views/login/index'),
          name: '首页',
        }
      ]
    }) //给子路由添加一条数据
    router.addRoute(newT);
    next({
      path: to.path,
      replace: true
    });
  } else {
    next()
  }
  console.log(router);/* 路由对象 */
  console.log(router.options.routes);/* 前端路由实例对象数组(根组) */
  console.log(router.options.routes[0]);/* 需要在里面动态添加子路由的一个根组,插入的也是这个根组对象 */
  console.log(router.options.routes[0].children);/* 获取这个根组下的子路由数组 */

  // router.addRoute(routeList) // 动态添加可访问路由表
  // // next()
  // router.addRoute(routeList)
  // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
  // console.log(router)

  // store.dispatch('GetInfo').then(res => { // 拉取info
  //   const roles = res.data.role;
  //   store.dispatch('GenerateRoutes', { roles }).then(e => { // 生成可访问的路由表
  //     console.log(e)
  //     router.addRoute(routeList) // 动态添加可访问路由表
  //     next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
  //   })
  // }).catch(err => {
  //   console.log(err);
  // });
  // next()
  // if (to.path === '/HomeView' || to.path === '/HomeView') {
  //   next()
  // }
  // if (store.getters.token) { // 判断是否有token
  //   if (to.path === '/login') {
  //     next({ path: '/' });
  //   } else {
  //     if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
  //       store.dispatch('GetInfo').then(res => { // 拉取info
  //         const roles = res.data.role;
  //         store.dispatch('GenerateRoutes', { roles }).then(() => { // 生成可访问的路由表
  //           router.addRoute(store.getters.addRouters) // 动态添加可访问路由表
  //           next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
  //         })
  //       }).catch(err => {
  //         console.log(err);
  //       });
  //     } else {
  //       next() //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
  //     }
  //   }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
  //     next();
  //   } else {
  //     next('/login'); // 否则全部重定向到登录页
  //   }
  // }
});