<template>
  <div class="header_content">
    <div class="myinfo_box">
      <div class="myinfo_box_notice" @click="notice">
        <div class="myinfo_box_notice_round" v-if="isRedPoint == 1"></div>
        <div class="iconfont icon-xiaoxitongzhitixinglingshenglingdang icon_rotate45"></div>
      </div>
      <div class="myinfo_box_head_pic">
        <img class="myinfo_box_head_pic_min" v-if="headImg == 'null'" src="@/assets/img/head_pic.png" alt="">
        <img class="myinfo_box_head_pic_min" v-else :src="this.headPortrait" alt="">
      </div>
      <div class="myinfo_box_name" @click="showloginOut()">
        {{ name }}
      </div>
      <div class="myinfo_box_detail" @click="showloginOut()">
        <span class="iconfont icon-xiajiantou"></span>
      </div>
      <div class="login_out" v-if="(loginOutFlag == true)" @click="tologinOut()">
        <div class="iconfont icon-dengchu login_out_icon"></div>
        <div class="login_out_text">登出</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { loginOut, haveNews, editBright } from '@/api/api.js'
  import bus from "../../../utils/bus";
  import { warningClose } from '@/utils/message';
  export default {
    components: {},
    data() {
      return {
        loginOutFlag: false,
        name: localStorage.getItem('username'),
        headPortrait: localStorage.getItem('imgUrl') + localStorage.getItem('headPortrait'),
        headImg: localStorage.getItem('headPortrait'),
        isRedPoint: '', // 1 有红点
      }
    },
    watch: {
      $route(to, from) {
        this.loginOutFlag = false
      },
      headPortrait(a, b) {
      
      }
    },

    created() {
      this.haveNews();
      // this.editBright();
      bus.$on('name', (data) => {
        this.isRedPoint = data
      })

    },
    beforeDestroy() {
      bus.$off('name');
    },
    methods: {
      async haveNews() {
        await haveNews().then(res => {
          if (res.code == 200 && res.data != null) {
            this.isRedPoint = res.data.isBright
          }
        })
      },
      async editBright() {
        await editBright().then(res => {
          if (res.code == 200) {
            this.haveNews();
          }
        })
      },
      notice() {
        this.$router.push({ path: './noticeDataManage' })
        this.editBright()
        // this.isRedPoint = 1
      },
      showloginOut() {
        this.loginOutFlag = !this.loginOutFlag
      },
      async tologinOut() {
        warningClose();
        await loginOut().then(res => {
          if (res.code == 200) {
            this.$router.push({ path: './login' })
          }
        })
      }

    }
  }
</script>

<style scoped>
  .header_content {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px !important;
    width: 100%;
  }

  .myinfo_box {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    height: 80px;
  }

  .myinfo_box_notice {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 34px;
    height: 34px;
    margin-right: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.37);
  }

  .myinfo_box_notice_round {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f85819;
  }

  .myinfo_box_head_pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-right: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px rgba(2, 48, 103, 0.37);
  }

  .myinfo_box_head_pic_min {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .myinfo_box_name {
    display: flex;
    cursor: pointer;
    width: auto;
    height: 22px;
    line-height: 22px;
    margin-right: 10px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
  }

  .myinfo_box_detail {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 8px;
    height: 20px;
    color: #ffffff;
  }

  .icon_rotate45 {
    width: 18px;
    height: 18px;
    color: #009ea7;
    transform: rotate(45deg);
  }

  .icon-xiajiantou {
    font-size: 12px !important;
  }

  .login_out {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 68px;
    right: 10px;
    width: 110px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 3px 5px 0px rgba(2, 48, 103, 0.1);
    border-radius: 8px;
  }

  .login_out_text {
    width: 34px;
    font-size: 14px;
    color: #1d527a;
    line-height: 19px;
  }

  .login_out_icon {
    font-size: 14px;
    margin-right: 4px;
  }

  /* 媒体查询 自适应 */
  @media screen and (max-width: 1680px) {
    .header_content {
      padding-right: 26px !important;
    }

    .myinfo_box {
      height: 53px;
    }

    .myinfo_box_notice {
      width: 22px;
      height: 22px;
      margin-right: 13px;
    }

    .myinfo_box_notice_round {
      width: 7px;
      height: 7px;
    }

    .myinfo_box_head_pic {
      width: 29px;
      height: 29px;
      margin-right: 9px;
    }

    .myinfo_box_head_pic_min {
      width: 27px;
      height: 27px;
      border-radius: 50%;
    }

    .myinfo_box_name {
      height: 15px;
      line-height: 15px;
      margin-right: 7px;
      font-size: 12px;
    }

    .myinfo_box_detail {
      width: 5px;
      height: 13px;
    }

    .icon_rotate45 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 16px;
    }

    .login_out {
      top: 45px;
      right: 7px;
      width: 73px;
      height: 20px;
      border-radius: 5px;
    }

    .login_out_text {
      width: 25px;
      line-height: 13px;
      font-size: 12px;
    }

    .login_out_icon {
      font-size: 12px;
      margin-right: 3px;
    }
  }
</style>