// 引入路由和storage工具函数
import router from "../router"
import { loginOut } from '@/api/api.js'
import { warningClose } from '@/utils/message';
var lastTime = new Date().getTime() //点击的最后一次时间
var currentTime = new Date().getTime() //现在时间
var timeOut = 5 * 1000 * 100; //设置超时时间: 5分钟

window.document.onclick = function () {
  // console.log(123123)
  //监听页面的点击事件，点击一次向sessionStorage中存储点击的时间
  sessionStorage.setItem("lastTime", new Date().getTime())
}
//封装一个函数 10秒调用一次
async function checkTimeout() {
  currentTime = new Date().getTime() //更新当前时间
  var lastTime = sessionStorage.getItem("lastTime"); //获取最后一次点击的时间
  if (currentTime - lastTime > timeOut) { //判断是否超时
    // 跳到登陆页
    if (router.history.current.fullPath == '/login') return // 当前已经是登陆页时不做跳转
    await loginOut().then(res => {
      console.log("登出", res.data)
      if (res.code == 200) {
        warningClose();
        router.push({ path: '/login' })
      }
    })
    // router.push({
    //   path: '/login',
    //   name: 'login'
    // })
    //重新加载页面
    // location.reload()
  }
}

export default function () {
  /* 定时器 间隔10秒检测是否长时间未操作页面 */
  console.log('$$$$$$$$$$$$$$$$$$  $$$$$$$$$$$$$$$$$$$计时器')
  window.setInterval(checkTimeout, 500000);
}

