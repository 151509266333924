// 引入封装好的axios
import axios, { apiUrl } from './request'
// 定义跨域代理路由
console.log(apiUrl())
// const api = "http://192.168.100.157:8081"
// const api = "http://192.168.100.153:9901"
// const api = "http://192.168.100.160:9901"
// const api = "http://192.168.100.156:9901"
// const api = "http://192.168.100.66:9901"
// export const api = process.env.VUE_APP_BASE_API

// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
const api = process.env.NODE_ENV == 'development' ? '/devapi' : apiUrl()

// const api2 = process.env.NODE_ENV == "development" ? '/test2' : 'http://52.81.97.167:8088'
// const loginapi = process.env.NODE_ENV == "development" ? '/devapi' + '/service-organiza' : '/api'
//  /api 为配置跨域的路径变量
// 登出
// export const logout = (params) => {
//     return axios.get(loginapi + '/ums/login/logout')
// }

// 登录
export const login = (params) => { // 原来接口是这样的
  return axios.post(api + '/apiWeb/user/login', params)
}

// 修改密码
export const updatePassword = (params) => {
  return axios.post(api + '/apiWeb/user/changePwd', params)
}

// 忘记密码
export const resetPwd = (params) => {
  return axios.post(api + '/apiWeb/user/resetPwd', params)
}

// 登出
export const loginOut = () => {
  return axios.post(api + '/apiWeb/user/logout')
}

// 左侧菜单
export const getLeftMenu = (params) => {
  return axios.get(api + '/apiWeb/auth/getAuthDetail?' + params)
}
// 页面里的按钮权限
export const getOperationAuth = (params) => {
  return axios.get(api + '/apiWeb/auth/getOperationAuthByauthId',{
    params:params
  })
}
// 职位 字典
export const getPositionList = (params) => {
  return axios.get(api + '/apiWeb/getCodeInfo?' + params)
}

// 疾病大类
export const getDiseaseOneList = () => {
  return axios.get(api + '/apiWeb/diseaseList')
}

// 科普推文的疾病大类
export const getLevelDisease = (params) => {
  return axios.get(api + '/apiWeb/disease/getLevelDisease?' + params)
}

// 知识库的疾病大类
export const getDiseaseList1 = (params) => {
  return axios.get(api + '/apiWeb/diseaseList?' + params)
}

// 疾病子类
export const getDiseaseTwoList = (params) => {
  return axios.get(api + '/apiWeb/diseaseList?' + params)
}
// 一级子类、二级子类。。。
export const getDiseaseChild = (params) => {
  return axios.get(api + '/apiWeb/getDiseaseChild?' + params)
}

// 医院列表
export const getHospitalList = () => {
  return axios.get(api + '/apiWeb/hospital/hospitalListSelect')
}

// 科室列表
export const getDepartmentList = (params) => {
  return axios.get(api + '/apiWeb/department/departmentListSelect?' + params)
}

// 病患大类信息
export const doctorQuerySelect = (params) => {
  return axios.get(api + '/apiWeb/doctorQuerySelect?' + params)
}
export const doctorQuerySelectNew = (params) => {
  return axios.get(api + '/apiWeb/doctorQuerySelectNew?' + params)
}

// 疾病下拉列表
// export const getDiseaseList = () => {
//   return axios.get(api + '/apiWeb/disease/diseaseWebListSelect')
// }

// 上传图片
export const uploadImg = (params) => {
  return axios.post(api + '/apiWeb/uploadWeb', params, { type: 'upload' })
}

export const uploadWebMessage = (params) => {
  return axios.post(api + '/apiWeb/uploadWebMessage', params, { type: 'upload' })
}

// 医生列表
export const getDoctorList = (params) => {
  return axios.get(api + '/apiWeb/getDoctorList?' + params)
}

// 是否有新消息
export const haveNews = () => {
  return axios.get(api + '/apiWeb/work/haveNews')
}

// 修改消息高亮
export const editBright = () => {
  return axios.post(api + '/apiWeb/work/editBright')
}

// 下载图片
export const downloadFile = (params) => {
  return axios.get(api + '/apiWeb/download?' + params, { type: 'export' })
}

// 订单一览
export const getOrderList = (params) => {
  return axios.post(api + '/apiWeb/order/searchOrderList',params)
}
// 订单详情
export const getOrderDetail = (params) => {
  return axios.get(api + '/apiWeb/order/searchOrder',{
    params:params
  })
}
// 获取检测机构
export const getAssistantList = (params) => {
  return axios.get(api + '/apiWeb/order/getAssistantList')
}
// 治疗方案一览
export const getTreatmentPlan = (params) => {
  return axios.get(api + '/apiWeb/treatmentPlanPage',{
    params:params
  })
}
// 治疗方案编号
export const getTreatmentNo = () => {
  return axios.get(api + '/apiWeb/getTreatmentNo')
}
// 治疗方案编辑
export const updateTreatmentPlan = (params) => {
  return axios.post(api + '/apiWeb/updateTreatmentPlan',params)
}
// 治疗方案新建
export const addTreatmentPlan = (params) => {
  return axios.post(api + '/apiWeb/addTreatmentPlan',params)
}
// 治疗方案新删除
export const delTreatmentPlan = (params) => {
  return axios.post(api + '/apiWeb/delTreatmentPlan',params)
}
// 治疗方案获取详情
export const getTreatmentPlanById = (params) => {
  return axios.get(api + '/apiWeb/getTreatmentPlanById',{
    params:params
  })
}
// 编辑治疗方案状态
export const editStatusTreatmentPlan = (params) => {
  return axios.post(api + '/apiWeb/editStatusTreatmentPlan',params)
}
// 基础检测项目一览
export const targetBasisWebList = (params) => {
  return axios.get(api + '/apiWeb/targetBasisWebList',{
    params:params
  })
}
// 基础检测项目新增
export const addBasisTarget = (params) => {
  return axios.post(api + '/apiWeb/createBasisTarget',params)
}
// 基础检测项目编辑
export const updateBasisTarget = (params) => {
  return axios.post(api + '/apiWeb/updateBasisTarget',params)
}
// 基础检测项目更改状态
export const updateBasisTargetStatus = (params) => {
  return axios.post(api + '/apiWeb/updateBasisTargetStatus',params)
}
// 基础检测项目删除
export const deleteBasisTarget = (params) => {
  return axios.get(api + '/apiWeb/deleteBasisTarget',{
    params:params
  })
}
// 基础检测项目导出
export const exportBasisTarget = (params) => {
  return axios.get(api + '/apiWeb/exportTargetBasis?'+params,{ type: 'export' })
}
// 订单导出
export const exportOrderTarget = (params) => {
  return axios.get(api + '/apiWeb/order/exportOrder?'+params,{ type: 'export' })
}