<template>
    <el-tooltip class="item" effect="light" :content="tip" :disabled="!tooltipDisabled" :visible-arrow="false"
        placement="top">
        <div ref="textElementRef" v-resize="domResize" class="ellipsis" :style="{
            width: maxWidth + 'px',
            height: maxHeight + 'px',
            lineClamp: lineClamp,
            WebkitLineClamp: lineClamp,
        }">
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
export default {
    props: {
        tip: {
            type: String,
            required: false,
            default: "",
        },
        maxWidth: {
            type: Number,
            required: false,
            default: null,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: null,
        },
        // 显示行数
        lineClamp: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    data() {
        return {
            tooltipDisabled: false,
        }
    },
    directives: {
        "resize": {
            bind(el, binding) {
                // 指令绑定到元素上时调用
                let width = 0
                let height = 0
                function isResize() {
                    const style = document.defaultView.getComputedStyle(el)
                    if (width !== style.width || height !== style.height) {
                        binding.value({ width: style.width, height: style.height })
                    }
                    width = style.width
                    height = style.height
                }
                el.__vueSetInterval__ = setInterval(isResize, 300)
            },
            componentUpdated(el, binding, vnode, oldVnode) {
                // 被绑定元素所在模板完成一次更新周期时调用
                clearInterval(el.__vueSetInterval__)
            },
        }
    },
    watch: {
        tip: {
            handler(val) {
                setTimeout(() => {
                    this.compareSize()
                })
            },
            deep: true,
        },
    },
    methods: {
        domResize() {
            this.compareSize()
        },
        compareSize() {
            const el = this.$refs.textElementRef
            const compareWidth = el ? el.scrollWidth > el.clientWidth : false
            const compareHeight = el ? el.scrollHeight > el.clientHeight : false
            this.tooltipDisabled = compareWidth || compareHeight
        }
    },
    mounted() {
        this.compareSize()
    }
}
</script>
<style scoped>
.ellipsis {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: normal;
}
</style>