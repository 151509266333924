import Vue from 'vue'
import Vuex from 'vuex'
import {  getOperationAuth } from '@/api/api.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: '',
    isFlag: '',
    powerList:[]
  },
  getters: {
  },
  mutations: {
    SET_POWER_LIST(state,power){
      state.powerList = power
    }
  },
  actions: {
    getPowerList({commit}){
      getOperationAuth({
        authId: localStorage.getItem('roleId')
      }).then(res => {
        commit('SET_POWER_LIST',res.data)
      })
    }
  },
  modules: {
  }
})
